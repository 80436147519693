import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {ImageItem} from '@PosterWhiteboard/items/image-item/image-item.class';
import {doesPublicFileExistAsync} from '@Utils/file.util';
import {openMessageModal} from '@Modals/message-modal';
import {GA4EventName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import type {ImageSlideItem} from '@PosterWhiteboard/items/slideshow-item/slide-items/image-slide-item.class';
import {MODAL_IDS, openModal} from '@Components/modal-container-deprecated';
import {getImageItemBigScreenSizeRemovedBackgroundUrl, REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION, REMOVED_BG_IMAGE_EXTENSION} from '@Libraries/image-item.library';
import {ItemLoadingProgressType} from '@PosterWhiteboard/items/item/item.types';
import {setImageBgRemovalCreditsCost} from '@Components/image-bg-removal-credits-cost/image-bg-removal-credits-cost-slice';
import {removeImageBackground} from '@Utils/image.util';
import {triggerBuyMoreCredits} from '@Components/poster-editor/library/poster-editor-open-modals';
import {updateTeamCredits} from '@Components/team-credits/team-credits-slice';
import {getCreditsCostForImageBackgroundRemoval} from '@Components/image-bg-removal-credits-cost/image-bg-removal-credits-cost-library';
import {getTeamCredits} from '@Components/team-credits/team-credits-library';

export interface RemoveImageItemBackgroundObject {
  isBackgroundRemoved: boolean;
  isBackgroundBeingRemoved: boolean;
}

export class RemoveImageItemBackground extends CommonMethods {
  public isBackgroundRemoved = false;
  public isBackgroundBeingRemoved = false;
  // TODO: This is done to prevent isBackgroundBeingRemoved from coming from undo stack. This is wrong. Think of a better way because right now i can't use updateFromObject for this val because copy is ignored.
  protected ignoreKeysForCopy = {
    isBackgroundBeingRemoved: null,
  };

  private item: ImageItem | ImageSlideItem;

  constructor(item: ImageItem | ImageSlideItem) {
    super();
    this.item = item;
  }

  public toObject(): RemoveImageItemBackgroundObject {
    return {
      isBackgroundRemoved: this.isBackgroundRemoved,
      isBackgroundBeingRemoved: this.isBackgroundBeingRemoved,
    };
  }

  public async ensureRemovedBackgroundImageExistsIfNeeded(): Promise<void> {
    if (this.isBackgroundRemoved && !(await this.doesRemovedBackgroundExists())) {
      await removeImageBackground(this.item.hashedFilename);
    }
  }

  public async doesRemovedBackgroundExists(): Promise<boolean> {
    const destURLScreen = getImageItemBigScreenSizeRemovedBackgroundUrl(this.item.hashedFilename, this.item.imageSource);
    return doesPublicFileExistAsync(destURLScreen);
  }

  public async removeBackground(): Promise<void> {
    if (this.isBackgroundBeingRemoved) {
      return;
    }

    this.isBackgroundBeingRemoved = true;
    this.item.page.poster.redux.updateReduxData();
    try {
      if (await this.doesRemovedBackgroundExists()) {
        if (this.isBackgroundRemoved) {
          return;
        }

        await this.item.enableRemoveBackgroundFlag();
        return;
      }

      this.item.loading.startLoading({
        langTextKeys: ['pmwjs_removing_background_percentage', 'pmwjs_removing_bg_percentage'],
        progress: {
          type: ItemLoadingProgressType.FAKE,
          expectedTotalTime: 20,
        },
      });
      const response = await removeImageBackground(this.item.hashedFilename);
      window.PMW.redux.store.dispatch(
        setImageBgRemovalCreditsCost({
          imageKey: this.item.hashedFilename,
          credits: 0,
        })
      );
      window.PMW.redux.store.dispatch(
        updateTeamCredits({
          credits: response.remainingRemoveBackgroundCredits,
        })
      );

      await this.item.enableRemoveBackgroundFlag();
    } catch (e: any) {
      if (e.code === 'unprocessable_image') {
        openMessageModal({
          title: window.i18next.t('pmwjs_remove_image_bg_failed'),
          text: window.i18next.t('pmwjs_remove_bg_unprocessable_image'),
        });
        return;
      }

      if (e.code === 'insufficient_credits') {
        if (window.posterEditor?.config?.isUnifiedCredits) {
          void this.onInSufficientFundsV2();
        } else {
          void this.onInSufficientFunds();
        }
        return;
      }

      openMessageModal({
        title: window.i18next.t('pmwjs_remove_image_bg_failed'),
        text: window.i18next.t('pmwjs_remove_image_bg_failed_desc'),
      });
    } finally {
      trackPosterBuilderGA4Events(GA4EventName.REMOVE_BACKGROUND);
      this.isBackgroundBeingRemoved = false;
      this.item.loading.removeLoading();
      this.item.page.poster.redux.updateReduxData();
    }
  }

  private onInSufficientFunds(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const cb = async (status: boolean): Promise<void> => {
        if (status) {
          await this.removeBackground();
          resolve();
          return;
        }
        reject();
      };

      // CodeReviewHamza: This should only take callback. Rest should be loaded in the modal
      window.PMW.readLocal('premium/getBackgroundRemovalPacks')
        .then((backgroundRemovalPacks: any) => {
          window.PMW.readLocal('premium/getRemainingBackgroundRemovals')
            .then((currentAssets: any) => {
              openModal(MODAL_IDS.REMOVE_BG_PURCHASE_MODAL_ID, {
                remainingBackgroundRemovals: currentAssets.remaningBackgroundRemovals,
                currentCredits: currentAssets.credits,
                backgroundRemovalPacks,
                callback: cb,
              });
            })
            .catch(() => {
              reject();
            });
        })
        .catch(() => {
          reject();
        });
    });
  }

  // TODO: Rename this back to onInSufficientFunds once window.posterEditor?.config?.isUnifiedCredits is permanently set to true.
  private onInSufficientFundsV2(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      const cb = async (credits: number): Promise<void> => {
        window.PMW.redux.store.dispatch(updateTeamCredits({credits}));
        await this.removeBackground();
        resolve();
      };

      try {
        const creditsToCharge: number = await this.getCreditsToChargeForBackgroundRemovalForUser();
        const remainingCredits = await getTeamCredits();

        triggerBuyMoreCredits({
          headingText: window.i18next.t('pmwjs_purchase_more_credits_continue_using_ai_bg_remover_ai_images_and_more'),
          modalTitle: window.i18next.t('pmwjs_youre_out_of_credits'),
          cb,
        });
      } catch (e) {
        console.error(e);
        reject();
      }
    });
  }

  public async getCreditsToChargeForBackgroundRemovalForUser(): Promise<number> {
    return getCreditsCostForImageBackgroundRemoval(this.item.hashedFilename, this.item.imageSource);
  }

  // TODO: Remove this function please its full of code smell
  public async getBackgroundRemovedURLForCurrentImageURL(): Promise<string> {
    const url = await this.item.getImageUrl();
    const removeBGURL = url.replace(REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION, '');
    const splitted = removeBGURL.split('.');
    const fileNameSplitted = splitted[splitted.length - 2].split('_');
    splitted[splitted.length - 2] = `${fileNameSplitted[0]}${REMOVE_BG_POST_FIX_NAME_WITHOUT_EXTENSION}${fileNameSplitted[1] !== undefined ? `_${fileNameSplitted[1]}` : ''}`;
    splitted[splitted.length - 1] = REMOVED_BG_IMAGE_EXTENSION;
    return splitted.reduce((left, curr) => {
      return `${left}.${curr}`;
    });
  }
}
