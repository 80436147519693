import type * as Fabric from '@postermywall/fabricjs-2';
import type {MaskingItemObject} from '@PosterWhiteboard/classes/masking/masking-item.class';
import {MaskingItem} from '@PosterWhiteboard/classes/masking/masking-item.class';
import {MaskingType} from '@PosterWhiteboard/classes/masking/masking.class';
import type {TextStylesObject} from '@PosterWhiteboard/classes/text-styles.class';
import {TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {addFonts} from '@Libraries/font-library';
import type {DeepPartial} from '@/global';
import {Textbox} from '@postermywall/fabricjs-2';

export type MaskingTextStyles = Omit<TextStylesObject, 'fill' | 'fontStyle' | 'fontWeight' | 'fontLicense' | 'script' | 'stroke' | 'strokeColor' | 'strokeWidth'>;

export interface MaskingTextObject extends MaskingItemObject {
  textStyles: MaskingTextStyles;
  text: string;
}

export const MAX_FONT_SIZE = 200;
export const MIN_FONT_SIZE = 8;
export const MAX_LETTER_SPACING = 200;
export const MIN_LETTER_SPACING = -20;
export const MAX_LEADING = 500;
export const MIN_LEADING = 10;

export class MaskingText extends MaskingItem {
  public type: MaskingType = MaskingType.TEXT;
  public text = '';
  public textStyles: TextStyles;

  constructor() {
    super();
    this.textStyles = new TextStyles();
  }

  public copyVals(obj: DeepPartial<MaskingTextObject> = {}): void {
    const {textStyles, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.textStyles.copyVals(textStyles);
  }

  public toObject(): MaskingTextObject {
    return {
      ...super.toObject(),
      textStyles: this.textStyles.toObject(),
      text: this.text,
    };
  }

  public async applyMaskingToImage(img: HTMLImageElement): Promise<HTMLImageElement> {
    const fabricObject = await this.getFabricObjectForMasking();
    const scaleX = img.width / this.imageWidth;
    const scaleY = img.height / this.imageHeight;
    fabricObject.set({
      angle: this.angle,
      width: this.width,
      height: this.height,
      scaleX: this.scaleX * scaleX,
      scaleY: this.scaleY * scaleY,
      left: this.left * scaleX,
      top: this.top * scaleY,
    });

    return this.applyMaskForFabricObject(img, fabricObject);
  }

  private async getFabricObjectForMasking(): Promise<Fabric.Object> {
    return new Promise((resolve, reject) => {
      addFonts(
        [this.textStyles.getFontFamilyToLoad()],
        () => {
          resolve(
            new Textbox(this.text, {
              angle: this.angle,
              left: this.left,
              top: this.top,
              width: this.width,
              scaleX: this.scaleX,
              scaleY: this.scaleY,
              ...this.textStyles.getTextStyles(this.getScaledWidth(), this.getScaledHeight()),
            })
          );
        },
        reject
      );
    });
  }

  private getScaledWidth(): number {
    return this.width * this.scaleX;
  }

  private getScaledHeight(): number {
    return this.height * this.scaleY;
  }
}
