import type {ItemFabricObject} from '@PosterWhiteboard/items/item/item.class';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {MediaItemSizeType, repoURL} from '@Libraries/s3-library';
import {doesPublicFileExistAsync} from '@Utils/file.util';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ItemEffectsObject} from '@PosterWhiteboard/classes/item-effects.class';
import {ItemEffects} from '@PosterWhiteboard/classes/item-effects.class';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import type {ItemCropObject} from '@PosterWhiteboard/classes/item-crop.class';
import {ItemCrop} from '@PosterWhiteboard/classes/item-crop.class';
import type {RemoveImageItemBackgroundObject} from '@PosterWhiteboard/items/image-item/remove-image-item-background.class';
import {RemoveImageItemBackground} from '@PosterWhiteboard/items/image-item/remove-image-item-background.class';
import type {ItemMaskingObject} from '@PosterWhiteboard/classes/item-masking.class';
import {ItemMasking} from '@PosterWhiteboard/classes/item-masking.class';
import {isEqual} from 'lodash';
import {DEFAULT_SHADOW_BLUR, DEFAULT_SHADOW_DISTANCE} from '@PosterWhiteboard/classes/item-aura.class';
import {onOpenMaskingModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import editIconSVG from '@PosterWhiteboard/poster/poster-item-control-svgs/edit-icon.svg';
import replaceIconSVG from '@PosterWhiteboard/poster/poster-item-control-svgs/replace-icon.svg';
import type {ImageItemSourceType} from '@Libraries/image-item.library';
import {getDirPathForImageItem, getRemoveBGPostFix, getUrlForImageItemScreenTier, ImageItemSource, SCREEN_TIER_DETAILS, ScreenTier} from '@Libraries/image-item.library';
import type {MaskingObject} from '@PosterWhiteboard/classes/masking/masking.class';
import {mapNumberToRange} from '@Utils/math.util';
import {PosterVersion} from '@PosterWhiteboard/poster/poster.types';
import {cloneAsAlignedImage, getCopyProtectionOverlayImage} from '@Utils/fabric.util';
import {updateImagePopUpState} from '@Components/poster-editor/components/image-item-popup/image-item-popup-slice';
import {openReplaceMediaModal} from '@Modals/replace-media-modal';
import {EDITOR_SMALL_SCREEN_WIDTH_THRESHOLD} from '@Components/poster-editor/poster-editor.types';
import {getWriteBucket} from '@Utils/s3.util';
import {getCompatibleImageFileExtension, hasTransparency, loadImageAsync} from '@Utils/image.util';
import type {RGB} from '@Utils/color.util';
import {rgbToHex} from '@Utils/color.util';
import {FabricImage} from '@postermywall/fabricjs-2';
import {getPmwBmBtnControl} from '@PosterWhiteboard/poster/poster-item-controls';
import type {DeepPartial} from '@/global';

/**
 * Reference image dimension size for which all effect values (shadow, glow etc) are defined.
 */
const EFFECTS_REFERENCE_DIMENSION = 500;

interface AdditionalOldValuesForReinit {
  screenTier: string;
  hasStrokeBorderType: boolean;
  solidBorderType: BorderType;
  solidBorderThickness: number;
  solidBorderColor: RGB;
}

export interface ImageItemObject extends BaseItemObject {
  hashedFilename: string;
  fileExtension: string;
  isRemoved: boolean;
  isPurchased: boolean;
  showReplaceButton: boolean;
  loadedImageHeight: number;
  loadedImageWidth: number;
  removeBackground: RemoveImageItemBackgroundObject;
  cropData: ItemCropObject;
  effects: ItemEffectsObject;
  masking: ItemMaskingObject;
  imageSource: ImageItemSourceType;
}

interface BottomButton {
  onClick(): void;

  text: string;
  icon: string;
  visible: boolean;
}

export class ImageItem extends Item {
  declare fabricObject: FabricImage;
  public gitype: ITEM_TYPE = ITEM_TYPE.IMAGE;
  public hashedFilename!: string;
  public fileExtension!: string;
  public effects: ItemEffects;
  public imageSource!: ImageItemSourceType;
  public removeBackground: RemoveImageItemBackground;
  public isRemoved = false;
  public isPurchased = false;
  public showReplaceButton = false;
  public cropData: ItemCrop;
  public masking: ItemMasking;
  public imageElement!: HTMLImageElement;
  /**
   * Dimensions of unedited image that is currently loaded
   */
  public loadedImageWidth!: number;
  public loadedImageHeight!: number;

  public hasTransparency?: boolean;

  public constructor(page: Page) {
    super(page);
    this.effects = new ItemEffects(this);
    this.cropData = new ItemCrop();
    this.masking = new ItemMasking();
    this.removeBackground = new RemoveImageItemBackground(this);
  }

  public toObject(): ImageItemObject {
    return {
      ...super.toObject(),
      cropData: this.cropData.toObject(),
      effects: this.effects.toObject(),
      hashedFilename: this.hashedFilename,
      fileExtension: this.fileExtension,
      isRemoved: this.isRemoved,
      isPurchased: this.isPurchased,
      removeBackground: this.removeBackground.toObject(),
      masking: this.masking.toObject(),
      showReplaceButton: this.showReplaceButton,
      imageSource: this.imageSource,
      loadedImageHeight: this.loadedImageHeight,
      loadedImageWidth: this.loadedImageWidth,
    };
  }

  protected getAdditionalOldValuesForReinit(): AdditionalOldValuesForReinit {
    return {
      screenTier: this.getTargetScreenTier(),
      hasStrokeBorderType: this.border.hasStrokeBorderType(),
      solidBorderType: this.border.solidBorderType,
      solidBorderThickness: this.border.solidBorderThickness,
      solidBorderColor: this.border.solidBorderColor,
    };
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const btn = this.getBmBtn();
    this.fabricObject.set({
      pmwBmBtnText: btn.text,
      pmwBmBtnIcon: btn.icon,
    });

    this.fabricObject.setControlsVisibility({
      pmwBmBtn: this.getBmBtn().visible,
      ml: !this.isLocked(),
      mt: !this.isLocked(),
      mr: !this.isLocked(),
      mb: !this.isLocked(),
    });
  }

  protected itemObjectHasDestructiveChanges(oldItemObject: ImageItemObject, oldAdditionalValues: AdditionalOldValuesForReinit): boolean {
    return (
      oldAdditionalValues.screenTier !== this.getTargetScreenTier() ||
      this.hasStrokeBorderChanged(oldAdditionalValues) ||
      this.hasCroppingChanged(oldItemObject) ||
      this.hasRemoveBackgroundChanged(oldItemObject) ||
      this.hasEdgeTypeChanged(oldItemObject) ||
      this.hasMaskingChanged(oldItemObject)
    );
  }

  private hasEdgeTypeChanged(oldValues: ImageItemObject): boolean {
    return oldValues.effects.edgeType !== this.effects.edgeType || oldValues.effects.edgeThickness !== this.effects.edgeThickness;
  }

  private hasCroppingChanged(oldValues: ImageItemObject): boolean {
    return !isEqual(this.cropData.toObject(), oldValues.cropData);
  }

  private hasMaskingChanged(oldValues: ImageItemObject): boolean {
    return !isEqual(this.masking.toObject(), oldValues.masking);
  }

  private hasRemoveBackgroundChanged(oldValues: ImageItemObject): boolean {
    return this.removeBackground.isBackgroundRemoved !== oldValues.removeBackground.isBackgroundRemoved;
  }

  private hasStrokeBorderChanged(oldValues: AdditionalOldValuesForReinit): boolean {
    return (
      (oldValues.hasStrokeBorderType || this.border.hasStrokeBorderType()) &&
      (this.border.solidBorderType !== oldValues.solidBorderType ||
        this.border.solidBorderThickness !== oldValues.solidBorderThickness ||
        rgbToHex(this.border.solidBorderColor) !== rgbToHex(oldValues.solidBorderColor))
    );
  }

  public async getFabricObjectsForPDF(): Promise<Array<ItemFabricObject>> {
    const objects = [];
    const multiplier = this.loadedImageWidth / this.getScaledWidth();

    objects.push(await cloneAsAlignedImage(this.fabricObject, {multiplier}, ['__PMWID']));
    if (this.hasGettyContent()) {
      objects.push(await getCopyProtectionOverlayImage(this.fabricObject, ['__PMWID']));
    }

    return objects;
  }

  public copyVals(obj: DeepPartial<ImageItemObject>): void {
    const {effects, masking, removeBackground, cropData, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.cropData.copyVals(cropData);
    this.effects.copyVals(effects);
    this.masking.copyVals(masking);
    this.removeBackground.copyVals(removeBackground);
  }

  protected onItemDoubleTapped(): void {}

  protected onItemDoubleClicked(): void {
    if (this.masking.maskingItem?.isText()) {
      void this.openMaskingModal();
    }
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    await this.effects.applyItemEffects();
  }

  public getScaledLoadedImageHeight(): number {
    return this.scaleY * this.loadedImageHeight;
  }

  public getScaledLoadedImageWidth(): number {
    return this.scaleX * this.loadedImageWidth;
  }

  public isDrawing(): boolean {
    return this.imageSource === ImageItemSource.DRAWING;
  }

  public isMasked(): boolean {
    return this.masking.maskingItem !== undefined;
  }

  public async enableRemoveBackgroundFlag(): Promise<void> {
    await this.updateFromObject({
      removeBackground: {
        isBackgroundRemoved: true,
      },
    });
  }

  public async disableRemoveBackgroundFlag(): Promise<void> {
    await this.updateFromObject({
      removeBackground: {
        isBackgroundRemoved: false,
      },
    });
  }

  public getFileExtension(): string {
    return getCompatibleImageFileExtension(this.fileExtension);
  }

  public isPremium(): boolean {
    return this.isNonPurchasedGetty() && !this.isRemoved;
  }

  public hasGettyContent(): boolean {
    return this.imageSource === ImageItemSource.GETTY || this.imageSource === ImageItemSource.GETTY_ILLUSTRATIONS;
  }

  public isNonPurchasedGetty(): boolean {
    return !this.isPurchased && (this.imageSource === ImageItemSource.GETTY || this.imageSource === ImageItemSource.GETTY_ILLUSTRATIONS);
  }

  public isItemTransparent(): boolean {
    return this.removeBackground.isBackgroundRemoved || this.isMasked() || this.hasTransparency || this.effects.hasEdgeTypeForStroke();
  }

  protected async getFabricObjectForItem(shouldMaintainScaledDimensions = false): Promise<FabricImage> {
    const previousLoadedImageWidth = this.loadedImageWidth;
    const previousLoadedImageHeight = this.loadedImageHeight;
    await this.loadImageItem();
    if (shouldMaintainScaledDimensions) {
      if (this.loadedImageWidth !== previousLoadedImageWidth || this.loadedImageHeight !== previousLoadedImageHeight) {
        this.scaleX = (this.scaleX * previousLoadedImageWidth) / this.loadedImageWidth;
        this.scaleY = (this.scaleY * previousLoadedImageHeight) / this.loadedImageHeight;
      }
    }

    return new FabricImage(this.imageElement, {
      ...super.getCommonOptions(),
    });
  }

  protected initCustomControls(): void {
    super.initCustomControls();

    const pmwBmBtnControl = getPmwBmBtnControl((): void => {
      this.onBmBtnClicked();
    });
    this.fabricObject.controls[pmwBmBtnControl.key] = pmwBmBtnControl.control;
  }

  protected async loadImageItem(): Promise<void> {
    await this.ensureDynamicSizesForImageExists();
    await this.removeBackground.ensureRemovedBackgroundImageExistsIfNeeded();
    let imageElement = await loadImageAsync(await this.getImageUrl());
    this.loadedImageWidth = imageElement.width;
    this.loadedImageHeight = imageElement.height;
    imageElement = await this.cropData.applyCropToImage(imageElement);
    imageElement = await this.masking.applyMaskingToImage(imageElement);
    imageElement = await this.effects.applyEdgeEffectsToImageElement(imageElement);
    imageElement = await this.border.applyBorderBeforeInitToImageElement(imageElement, this.getScaleFactorForEffects());
    this.imageElement = imageElement;
    this.initOriginalImageHasTransparency();
  }

  private initOriginalImageHasTransparency(): void {
    if (!this.page.poster.mode.isGeneration() && !this.isTransparencyDetermined()) {
      this.hasTransparency = this.isJPG() ? false : hasTransparency(this.imageElement);
    }
  }

  private isTransparencyDetermined(): boolean {
    return this.hasTransparency !== undefined;
  }

  protected isJPG(): boolean {
    return this.fileExtension.toLowerCase() === 'jpg';
  }

  private getImageDisplayHeight(): number {
    return this.imageElement.height;
  }

  private getImageDisplayWidth(): number {
    return this.imageElement.width;
  }

  private doesLoadedImageHaveDifferentDimensions(): boolean {
    return this.width !== this.getImageDisplayWidth() || this.height !== this.getImageDisplayHeight();
  }

  protected shouldMaintainScaledDimensionsOnReinit(oldItemObject: ImageItemObject, oldAdditionalValues: AdditionalOldValuesForReinit): boolean {
    return oldAdditionalValues.screenTier !== this.getTargetScreenTier();
  }

  public isIcon(): boolean {
    return this.imageSource === ImageItemSource.PMW_ICON;
  }

  public async getImageUrl(): Promise<string> {
    return this.loadHighRes() ? this.getHighresUrl() : this.getScreenUrl();
  }

  protected repoImageURL(sizeType?: MediaItemSizeType): string {
    if (this.hashedFilename && this.getFileExtension()) {
      let imageSizeType = sizeType;
      if (imageSizeType === undefined) {
        imageSizeType = this.page.poster.isHighRes ? MediaItemSizeType.HIGH_RES : MediaItemSizeType.SCREEN;
      }
      const imageName = this.getImageS3Name();

      return repoURL(`${getDirPathForImageItem(this.imageSource, imageSizeType)}/${imageName}`, getWriteBucket());
    }
    return '';
  }

  public getUnoptimizedOriginalHighresImageURL = (): string => {
    return repoURL(`${getDirPathForImageItem(this.imageSource, MediaItemSizeType.HIGH_RES)}/${this.hashedFilename}.${this.getFileExtension()}`, getWriteBucket());
  };

  private getImageS3Name(): string {
    let imageName = '';
    if (this.removeBackground.isBackgroundRemoved) {
      imageName = this.hashedFilename + getRemoveBGPostFix();
    } else {
      imageName = `${this.hashedFilename}.${this.getFileExtension()}`;
    }

    return imageName;
  }

  protected fixChanges(): void {
    this.fixBrightness();
    this.fixBorder();

    if (this.page.poster.version < PosterVersion.HTML5 && this.border.solidBorderType !== BorderType.NONE) {
      this.width -= this.border.solidBorderThickness;
      this.height -= this.border.solidBorderThickness;
    }
  }

  /**
   * Brightness used to have a range of -100 to 100 in HTML5 posterbuilder build using fabricJs (Version: 1.5),
   * After upgrading to fabricJs (version: 2.0), new range for brightness filter is -0.4 to 0.4 hence with the upgraded
   * posterBuilder we need to map the old brightness value of images.
   */
  private fixBrightness(): void {
    if (this.page.poster.version < PosterVersion.FABRIC_2_UPDATE) {
      this.effects.brightness = mapNumberToRange(this.effects.brightness, -100, 100, -0.4, 0.4);
    }
  }

  /**
   * Fix border. This bug was introduced when fabric was updated to version 3 but stroke for images was not set to uniform, so now
   * scale those borders according to image scale so that users don't see a difference
   */
  private fixBorder(): void {
    if (this.page.poster.version === PosterVersion.FABRIC_3_UPDATE) {
      this.border.solidBorderThickness = Math.round(this.border.solidBorderThickness * this.scaleX);
    }
  }

  private async ensureDynamicSizesForImageExists(): Promise<void> {
    if (!(await this.doDynamicSizeExists())) {
      await window.PMW.writeLocal('posterimage/generateImagePresets', {
        userImageHashedFilename: this.hashedFilename,
      });
    }
  }

  private async doDynamicSizeExists(): Promise<boolean> {
    return doesPublicFileExistAsync(this.getUrlForImageItemScreenTier(this.getTargetScreenTier()));
  }

  protected getScreenUrl(): string {
    return this.getDynamicSizeUrlForModel();
  }

  private getDynamicSizeUrlForModel(): string {
    const url = '';

    if (this.hashedFilename && this.getFileExtension()) {
      return this.getUrlForImageItemScreenTier(this.getTargetScreenTier());
    }

    return url;
  }

  protected getUrlForImageItemScreenTier(tier: ScreenTier): string {
    return getUrlForImageItemScreenTier(this.hashedFilename, this.imageSource, tier, this.removeBackground.isBackgroundRemoved);
  }

  private async getHighresUrl(): Promise<string> {
    return this.getOptimizedHighResImageURL();
  }

  private getTierForMaxImageDimension(imageMaxDimension: number): ScreenTier {
    let selectedTier = ScreenTier.SMALL;
    for (const [teirName, detail] of Object.entries(SCREEN_TIER_DETAILS)) {
      if (imageMaxDimension > detail.minTargetDimension && detail.minTargetDimension > SCREEN_TIER_DETAILS[selectedTier].minTargetDimension) {
        selectedTier = teirName as ScreenTier;
      }
    }
    return selectedTier;
  }

  private getTargetScreenTier(): ScreenTier {
    const targetDimensionPx = Math.max(this.getScaledLoadedImageWidth(), this.getScaledLoadedImageHeight());
    return this.getTierForMaxImageDimension(targetDimensionPx);
  }

  private async getOptimizedHighResImageURL(): Promise<string> {
    // In order to get watermark free image
    if (this.hasGettyContent()) {
      return this.getDynamicalyResizedHighresImageURL();
    }

    // getDynamicalyResizedHighresImageURL is disabled for now for drawing because the drawingToImage function it uses doesn't return accurate image
    if (this.isDrawing()) {
      this.getOptimizedHighResImageURLForDrawing();
    }

    const newMaxImageDimension = this.newMaxImageDimension();
    const selectedTier = this.getTierForMaxImageDimension(newMaxImageDimension);

    if (newMaxImageDimension <= SCREEN_TIER_DETAILS[selectedTier].maxDimension) {
      return this.getUrlForImageItemScreenTier(selectedTier);
    }

    return this.getDynamicalyResizedHighresImageURL();
  }

  private getOptimizedHighResImageURLForDrawing(): string {
    const newMaxImageDimension = this.newMaxImageDimension();
    const selectedTier = this.getTierForMaxImageDimension(newMaxImageDimension);
    return this.getUrlForImageItemScreenTier(selectedTier);
  }

  private newMaxImageDimension(): number {
    return Math.round(Math.max(this.getScaledHeight(), this.getScaledWidth()) * this.page.poster.scaling.scale);
  }

  private async getDynamicalyResizedHighresImageURL(): Promise<string> {
    const unoptimizedHighresImageURL = this.getUnoptimizedHighresImageURL();
    if (!this.shouldLoadDynamicallyResizedHighResImage()) {
      return unoptimizedHighresImageURL;
    }

    const newMaxDimension = this.newMaxImageDimension();

    try {
      return (await window.PMW.writeLocal('posterimage/resizeImageToMaxDimension', {
        newMaxDimension,
        imageUrl: unoptimizedHighresImageURL,
      })) as string;
    } catch (e) {
      return unoptimizedHighresImageURL;
    }
  }

  private shouldLoadDynamicallyResizedHighResImage(): boolean {
    return this.page.isMemoryIntensive();
  }

  private loadHighRes(): boolean {
    return this.page.poster.isHighRes || (this.page.poster.mode.isWebpage() && !this.isPremium());
  }

  public getUnoptimizedHighresImageURL(): string {
    if (this.isDrawing()) {
      return this.getUrlForImageItemScreenTier(ScreenTier.BIG);
    }
    return this.repoImageURL(MediaItemSizeType.HIGH_RES);
  }

  protected applyShadowToFabricObject(): void {
    this.fabricObject.set(
      'shadow',
      this.aura.getItemAura({
        shadowDistance: this.getScaleFactorForEffects() * DEFAULT_SHADOW_DISTANCE,
        shadowBlur: this.getScaleFactorForEffects() * DEFAULT_SHADOW_BLUR,
        glowVal: this.getScaleFactorForEffects() * this.aura.getDefaultGlowValue(),
      })
    );
  }

  /**
   * Returns a scale factor so that the effects appears the same on different size images
   */
  public getScaleFactorForEffects(): number {
    const xDiff = this.loadedImageWidth / EFFECTS_REFERENCE_DIMENSION;
    const yDiff = this.loadedImageHeight / EFFECTS_REFERENCE_DIMENSION;
    return Math.min(xDiff, yDiff);
  }

  public getColors(): Array<RGB> {
    const colors: Array<RGB> = super.getColors();

    if (this.aura.isGlow()) {
      colors.push(this.aura.glowColor);
    }
    return colors;
  }

  private onBmBtnClicked(): void {
    this.getBmBtn().onClick();
  }

  private getBmBtn(): BottomButton {
    if (this.masking.maskingItem?.isText()) {
      return {
        text: window.i18next.t('pmwjs_edit_mask_text'),
        onClick: this.openMaskingModal.bind(this),
        icon: editIconSVG as string,
        visible: true,
      };
    }

    return {
      text: window.i18next.t('pmwjs_replace'),
      onClick: this.onReplaceClicked.bind(this),
      icon: replaceIconSVG as string,
      visible: this.showReplaceButton,
    };
  }

  private openMaskingModal(): void {
    void onOpenMaskingModal(this);
  }

  private onReplaceClicked(): void {
    if (window.innerWidth < EDITOR_SMALL_SCREEN_WIDTH_THRESHOLD) {
      openReplaceMediaModal();
    } else {
      const isPopupOpen = window.PMW.redux.store.getState().imageItemPopUpMenu.openPopup;
      window.PMW.redux.store.dispatch(
        updateImagePopUpState({
          beforeOpen: !isPopupOpen,
          openPopup: isPopupOpen,
          target: this.page.activeSelection.getActiveObject(),
        })
      );
    }
  }

  public async updateMasking(maskingObject?: MaskingObject): Promise<void> {
    await this.updateFromObject({
      masking: {
        maskingItem: maskingObject,
      },
    });
  }
}
