import {setImageBgRemovalCreditsCost} from '@Components/image-bg-removal-credits-cost/image-bg-removal-credits-cost-slice';
import {getRemoveBackgroundCost} from '@Utils/image.util';
import type {StateProps} from './image-bg-removal-credits-cost-slice';

export const getCreditsCostForImageBackgroundRemoval = async (imageHashedFilename: string, imageSource: string): Promise<number> => {
  const imageBgRemovalCreditsMap = getImageBgRemovalCreditsMapFromStore();

  if (Object.keys(imageBgRemovalCreditsMap).length > 0) {
    if (imageHashedFilename in imageBgRemovalCreditsMap) {
      return imageBgRemovalCreditsMap[imageHashedFilename];
    }
  }

  const creditsToCharge: number = await getRemoveBackgroundCost(imageHashedFilename, imageSource);
  updateImageBgRemovalCredits(imageHashedFilename, creditsToCharge);
  return creditsToCharge;
};

export const updateImageBgRemovalCredits = (imageHashedFilename: string, updatedCredits: number): void => {
  window.PMW.redux.store.dispatch(
    setImageBgRemovalCreditsCost({
      imageKey: imageHashedFilename,
      credits: updatedCredits,
    })
  );
};

export const getImageBgRemovalCreditsMapFromStore = (): StateProps => {
  return window.PMW.redux.store.getState().imageBgRemovalCreditsCost;
};
