import {getTeamCreditsForUser} from '@Libraries/user.library';
import {updateTeamCredits} from '@Components/team-credits/team-credits-slice';

export const getTeamCredits = async (): Promise<number> => {
  const credits = getTeamCreditsFromStore();

  if (credits !== null) {
    return credits;
  }

  const teamCredits = await getTeamCreditsForUser();

  window.PMW.redux.store.dispatch(
    updateTeamCredits({
      credits: teamCredits,
    })
  );
  return teamCredits;
};

export const getTeamCreditsFromStore = (): number | null => {
  return window.PMW.redux.store.getState().teamCredits.credits;
};
